// i18next-extract-mark-ns-start bancontact

//Payment methods
import { Background } from "components/Background";
import { ContactSalesButton } from "components/ContactSalesButton";
import { Content } from "components/Content";
import { Section, SectionActions, SectionHeader, SectionImage } from "components/Section";
import { SEO } from "components/SEO";
import { SignUpButton } from "components/SignUpButton";
import { graphql } from "gatsby";
import { Trans, useI18next } from "gatsby-plugin-react-i18next";

//Partners
import index2 from "images/best_payment_gateway_rates.svg";

//Images
import bancontact_monei from "images/bancontact_monei.svg";

// Clients
import index1 from "images/payment_gateway.svg";

import React from "react";
import styled from "styled-components";
import { AnchorLink } from "components/AnchorLink";
import { List } from "components/List";
import { PaymentMethodsLinkList } from "components/landings/PaymetMethodsLinkList";
import { IndexBackground } from "components/landings/IndexBackground";
import { MainTitleContent } from "components/payment-methods/MainTitleContent";
import { MainTitleImg } from "components/payment-methods/MainTitleImg";
import { BlogLink } from "components/links/Blog";
import { IntegrationGuide } from "components/payment-methods/IntegrationGuide";
import { InternalPageLink } from "components/links/Pages";
import { SupportLink } from "components/links/Support";

const LargeSectionHeader = styled(SectionHeader)`
  h1 {
    font-size: 2.625rem;
    line-height: 3.25rem;

    @media (max-width: 768px) {
      font-size: 2rem;
      line-height: 2.75rem;
    }
  }
`;

const OrderList = styled.ol``;

const Bancontact: React.FC = () => {
  const {t} = useI18next();
  return (
    <>
      <SEO
        path="bancontact"
        title={t('Accept Bancontact (A Must-Have in Belgium E-commerce)')}
        description={t(
          'Reach customers in Belgium by accepting Bancontact — Belgian’s strongest payment method with over 224 million transactions in 2021.'
        )}
      />
      <IndexBackground>
        <Content>
          <Section reverseOnMobile>
            <MainTitleContent sx={{maxWidth: {all: '590px', md: '100%'}}}>
              <LargeSectionHeader underline tagName="h1">
                <Trans>Bancontact — your gateway to selling in Belgium</Trans>
              </LargeSectionHeader>
              <Trans parent="p">
                In order to reach customers in Belgium, it’s critical to accept Bancontact payments.
                The strongest payment method in Belgium is connected to 20 of the country’s banks,
                and its usage is growing.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
                <ContactSalesButton />
              </SectionActions>
            </MainTitleContent>
            <MainTitleImg src={bancontact_monei} alt="MONEI bancontact" title="MONEI bancontact" />
          </Section>
        </Content>
      </IndexBackground>
      <Background>
        <Content>
          <Section>
            <SectionImage
              src={index2}
              alt="MONEI Payment Gateway"
              title="MONEI Payment Gateway"
              width={600}
              height={350}
              mobileWidth={280}
            />
            <div>
              <SectionHeader>
                <Trans>Benefits of accepting Bancontact payments</Trans>
              </SectionHeader>

              <Trans parent="p">
                With over 224 million online transactions made in 2021, Bancontact accounts for 94%
                of all debit cards in circulation in Belgium. Let shoppers securely pay online and
                in-person with their Bancontact payment card.
              </Trans>
              <Trans parent="h3">Bancontact lets you:</Trans>
              <List>
                <Trans parent="li">Grow your e-commerce business in Belgium</Trans>
                <Trans parent="li">
                  Increase trust in your business by accepting a widely used Belgian payment method
                </Trans>
                <Trans parent="li">
                  Guarantee security with{' '}
                  <AnchorLink href="https://monei.com/blog/psd2/">
                    strong customer authentication
                  </AnchorLink>{' '}
                  (SCA)
                </Trans>
                <Trans parent="li">
                  Improve conversions thanks to a better payment process.
                </Trans>
                <Trans parent="li">
                  Accept in-person Bancontact payments from Belgian customers via{' '}
                  <InternalPageLink slug="monei-pay/qr">QR</InternalPageLink> codes
                </Trans>
                <Trans parent="li">Streamline the omnichannel shopping experience</Trans>
              </List>
              <SectionActions>
                <SignUpButton variant="dark">
                  <Trans>Discover MONEI</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
          <Section reverseOnMobile>
            <div>
              <SectionHeader>
                <Trans>Bancontact payment process</Trans>
              </SectionHeader>
              <Trans parent="p">Speed up the customer checkout process with Bancontact.</Trans>
              <OrderList>
                <Trans parent="li">
                  Shopper selects Bancontact as their preferred payment method at checkout
                </Trans>
                <Trans parent="li">
                  Shopper chooses to pay by QR code using the Payconiq by Bancontact app or by
                  entering their Bancontact card details
                </Trans>
                <Trans parent="li">
                  If shopper scans QR code, the payment is confirmed in the Payconiq by Bancontact
                  app
                </Trans>
                <Trans parent="li">
                  If shopper enters Bancontact card details, they’re redirected to their issuing
                  bank for{' '}
                  <BlogLink slug="what-is-3d-secure-and-its-advantages-for-e-commerce">
                    3DS
                  </BlogLink>{' '}
                  authentication
                </Trans>
                <Trans parent="li">You receive confirmation of payment</Trans>
              </OrderList>
              <Trans>
                Accepting Bancontact lets you offer a simple, safe, and popular payment method to
                Belgian consumers, but expanding your payment options shouldn’t end there. Reach
                more people by adding{' '}
                <InternalPageLink slug="payment-methods">many payment methods</InternalPageLink> including{' '}
                <InternalPageLink slug="payment-methods/credit-cards">cards</InternalPageLink> and{' '}
                <BlogLink slug="alternative-payment-methods">alternative payment methods</BlogLink>{' '}
                like{' '}
              </Trans>
              <PaymentMethodsLinkList exclude={['bancontact', 'credit-cards']} endTag={t('and')} />.
              <SectionActions>
                <SignUpButton variant="dark">
                  <Trans>Get MONEI Now</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
            <SectionImage
              src={index1}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={500}
              height={500}
              mobileWidth={280}
            />
          </Section>
        </Content>
      </Background>

      <IntegrationGuide title={<Trans>Get the Bancontact payment integration</Trans>}>
        <Trans>
          Adding Bancontact to your business is simple. All you have to do is sign up for MONEI.
          Once you’ve been approved,{' '}
          <SupportLink slug="/requests/new">contact support</SupportLink>{' '}
          to configure this payment method in your store. Start accepting cross-border payments
          today.
        </Trans>
      </IntegrationGuide>
    </>
  );
};

export default Bancontact;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "bancontact"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
